import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { ModalBody, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { ArticleHeader2 } from '../../../components/parent/article-header'
import ArticleBGWrapper from '../../../components/parent/article-bg-wrapper'
import UsefulTools from '../../../components/parent/useful-tools'
import {
  CuriosityCloseButton,
  CuriosityModal,
} from '../../../components/parent/curiosity'

import { ArticleWrapper, ArticleContainer } from '../../../components/elements'

const ExpertWrapper = styled.div`
  margin-bottom: 45px;

  .gatsby-image-wrapper {
    height: auto;
    max-width: 125px;
  }

  p {
    font-weight: 400;
    margin-top: 5px;
  }
`

export const Expert = ({ image, name }) => (
  <ExpertWrapper>
    <Img fluid={image} alt={name} />
    <p>{name}</p>
  </ExpertWrapper>
)

const Page = ({ location }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/payback/article3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 1342) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImage: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImageMobile: file(
        relativePath: { eq: "parent/curiosity/MOBILE/mobile_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Expert: file(relativePath: { eq: "parent/payback/expert3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 125) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Czy dawanie kieszonkowego się opłaca? Maciej Samcik"
        description="Mogłoby się wydawać, że dzieci i pieniądze to dwa odległe światy. Jeśli łączymy je w jakikolwiek sposób to najczęściej wtedy, kiedy uświadamiamy sobie, ile nas kosztuje utrzymanie dziecka lub kiedy komentujemy zgłaszane przez dziecko potrzeby finansowe."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader2
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        title={<>Czy dawanie kieszonkowego się opłaca?</>}
        linkPrev="/strefa-rodzica/kieszonkowe"
      />

      <ArticleBGWrapper>
        <ArticleWrapper style={{ marginTop: 0, fontWeight: 300 }}>
          <ArticleContainer>
            <Row>
              <Col xs={12} className="marginTop30">
                <Expert
                  image={data.Expert.childImageSharp.fluid}
                  name="Maciej Samcik"
                />

                <p>
                  Oczywiście, że się opłaca, dawanie kieszonkowego to{'\u00A0'}
                  najtańsza lekcja zarządzania pieniędzmi.{' '}
                </p>
                <p>
                  Dziecko, które nauczy się zarządzania małymi kwotami
                  udostępnionymi przez rodziców, nie popełni błędów, zarządzając
                  kiedyś dużymi, własnymi pieniędzmi. Mało który rodzic zdaje
                  sobie sprawę z{'\u00A0'}tego, że te{'\u00A0'}50 zł
                  kieszonkowego to{'\u00A0'}inwestycja, dzięki której dziecko
                  będzie bogatsze o{'\u00A0'}dziesiątki lub setki tysięcy
                  złotych w{'\u00A0'}przyszłości. Nic tak nie czyni ludzi
                  oszczędnymi i{'\u00A0'}rozsądnymi finansowo jak dobre
                  przyzwyczajenia.{' '}
                </p>
                <p>
                  Ludzie w{'\u00A0'}Polsce często nie mają oszczędności nie
                  dlatego, że ich dochody są zbyt niskie. Czasem jest tak tylko
                  dlatego, że nie mają właściwych nawyków. Jednym z{'\u00A0'}
                  największych problemów młodych osób wkraczających na{'\u00A0'}
                  rynek finansowy jest nieumiejętność: gromadzenia oszczędności,
                  dokonywania finansowych wyborów oraz budowania swojej
                  finansowej stabilności i{'\u00A0'}zamożności. Rolą
                  kieszonkowego jest to, żeby już w{'\u00A0'}młodym wieku
                  pokazać dziecku potęgę takich mechanizmów, jak procent
                  składany, czyli efekt śniegowej kuli oszczędności, cierpliwość
                  w{'\u00A0'}lokowaniu oszczędności czy przyjemność wynikająca z
                  {'\u00A0'}finansowej wolności, niezależności.
                </p>
                <p>
                  Oczywiście dziecko nie ma{'\u00A0'}szans w{'\u00A0'}młodym
                  wieku poczuć finansowej niezależności w{'\u00A0'}taki sposób
                  jak dorosły człowiek. Ale przecież może, a{'\u00A0'}nawet
                  powinno, już od{'\u00A0'}najmłodszych lat zasmakować
                  przyjemności wynikającej z{'\u00A0'}tego, że dzięki dobremu
                  zarządzaniu swoim kieszonkowym rosną jego „możliwości
                  finansowe”, oraz tego, że czasem lepiej mieć pieniądze, niż je
                  {'\u00A0'}wydać. Często w{'\u00A0'}dorosłym życiu wymieniamy
                  pieniądze na{'\u00A0'}rzeczy, które nie są tych pieniędzy
                  warte. Im{'\u00A0'}wcześniej tę prawdę poznamy, tym lepiej.
                </p>
              </Col>
            </Row>
          </ArticleContainer>
        </ArticleWrapper>

        <UsefulTools
          items={[
            {
              title: 'Ciekawostki',
              subtitle:
                'Rozmowy dzieci z dorosłymi o pieniądzach mają wielką wartość, czyli raport PISA',
              image: data.Tool3,
              onClick: toggleModal,
            },
            {
              title: 'Kolorowanki i rebusy',
              subtitle: 'Kolorowanka',
              image: data.Tool2,
              link: '/parent/Kolorowanka%201.pdf',
            },
            {
              title: 'Gry',
              subtitle: 'Bankosłówka',
              image: data.Tool1,
              link: '/parent/Bankoslowka.pdf',
            },
          ]}
        />
      </ArticleBGWrapper>

      <CuriosityModal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalBody>
          <CuriosityCloseButton onClick={toggleModal} />
          <div />
          <Img
            fluid={data.CuriosityBigImage.childImageSharp.fluid}
            className="curiosityImage"
          />
          <Img
            fluid={data.CuriosityBigImageMobile.childImageSharp.fluid}
            className="curiosityImageMobile"
          />
        </ModalBody>
      </CuriosityModal>
    </Layout>
  )
}

export default Page
